import enValidation from "@mono/validation/locales/en.json";
import arValidation from "@mono/validation/locales/ar.json";
import enConstants from "@mono/constants/i18n/locales/en.json";
import arConstants from "@mono/constants/i18n/locales/ar.json";
import enUI from "@mono/ui/i18n/locales/en.json";
import arUI from "@mono/ui/i18n/locales/ar.json";
import vuetifyMessages from "@mono/ui/i18n/vuetify";

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: "en",
  fallbackWarn: false,
  missingWarn: false,
  globalInjection: false,
  messages: {
    en: {
      ...enValidation,
      ...enConstants,
      ...vuetifyMessages.en,
      ...enUI,
    },
    ar: {
      ...arValidation,
      ...arConstants,
      ...vuetifyMessages.ar,
      ...arUI,
    },
  },
}));
